import React from 'react';
import styled, { css } from 'styled-components';

import { placeholderAnimation } from '@tager/web-components';

import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';

function PromotionPlaceholderCard() {
  return (
    <Component>
      <Inner>
        <Top>
          <ImageBlock />
        </Top>
      </Inner>
    </Component>
  );
}

const customPlaceholder = css`
  animation: ${placeholderAnimation} 2s linear;
  animation-iteration-count: infinite;
  background-image: linear-gradient(
    to right,
    rgb(0 0 0/ 3%) 0,
    rgb(0 0 0 / 3%) 15%,
    rgb(241 241 241 / 90%) 30%
  );
  background-color: var(--bg-color-placeholder);
  background-size: 1200px 100%;
`;

const ImageBlock = styled.div`
  ${customPlaceholder};
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
`;

const Component = styled.div`
  position: relative;
  padding: 12px;  
  flex: 1 1 20%;
  max-width: 20%;

  ${media.desktopLarge(css`
    padding: 16px;
  `)}

  ${media.tabletLarge(css`
    flex: 1 1 33.33%;
    max-width: 33.33%;
    padding: 17px;
  `)}
  
  ${media.mobile(css`
    flex: 1 1 50%;
    max-width: 50%;
  `)};

  ${media.mobileMedium(css`
    padding: 5px;
  `)};

  ${media.mobileSmall(css`
    flex: 1 1 100%;
    max-width: 100%;
    padding: 12px 0;
  `)};
`;

const Inner = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  background: #F5F5F5;
`;
const Top = styled.div`
  ${customPlaceholder};
  position: relative;
  min-height: 400px;
  display: flex;

  & > div {
    flex-grow: 1;
  }
`;
export default PromotionPlaceholderCard;
