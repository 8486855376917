import React, { useRef } from 'react';
import styled from 'styled-components';

import Breadcrumbs from '@/components/Breadcrumbs';
import Subscription from '@/components/Subscription';
import useCurrentPage from '@/hooks/useCurrentPage';
import { useLayout } from '@/components/Layout/Layout.hooks';
import { BreadCrumbs } from '@/typings/common';
import { setBreadCrumbsList } from '@/utils/common';

import PromotionContent from './components/PromotionContent';
import PromotionDescription from "./components/PromotionDescription";
import DownloadPDF from "@/components/DownloadPDF";

function Promotions() {
  const page = useCurrentPage();

  const componentRef = useRef<HTMLDivElement>(null);
  const { setThemePage } = useLayout();
  setThemePage('');

  const BREADCRUMB_LIST: Array<BreadCrumbs> = setBreadCrumbsList(
    {
      title: page?.title ?? '',
      path: page?.path ?? '',
    },
    { id: 2, title: 'Гипермаркет', path: '/hypermarkets' }
  );

  return (
    <Wrapper ref={componentRef}>
      <Breadcrumbs breadcrumbs={BREADCRUMB_LIST} />
      <PromotionContent title={page?.title ?? ''} componentRef={componentRef} />

      {page?.templateFields?.promotionText ? (
        <PromotionDescription text={page.templateFields.promotionText} />
      ) : null}

      <Subscription templateFields={page?.templateFields}/>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  overflow: hidden;
`;

export default Promotions;
