import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';

interface Props {
  paginationList: Array<number>;
  ActiveItem: number;
  setActiveItem: (value: number) => void;
  onClick: (page: number) => void;
}

function Pagination({
  paginationList,
  ActiveItem,
  setActiveItem,
  onClick,
}: Props) {
  const [isStartEllipsis, setStartEllipsis] = useState<boolean>(false);
  const [isEndEllipsis, setEndEllipsis] = useState<boolean>(false);
  const [finalList, setFinalList] = useState<Array<number>>();

  function changeActiveItem(index: number) {
    setActiveItem(index);
    onClick(index);
  }

  function nextItem() {
    setActiveItem(ActiveItem + 1);
    onClick(ActiveItem + 1);
  }

  function prevItem() {
    setActiveItem(ActiveItem - 1);
    onClick(ActiveItem - 1);
  }

  function renderList(paginationList: Array<number>) {
    if (paginationList.length < 7) {
      setStartEllipsis(false);
      setEndEllipsis(false);
      return paginationList.slice(1, paginationList.length - 1);
    } else if (ActiveItem < 5) {
      setStartEllipsis(false);
      setEndEllipsis(true);
      return paginationList.slice(1, 5);
    } else if (ActiveItem > paginationList.length - 4) {
      setStartEllipsis(true);
      setEndEllipsis(false);
      return paginationList.slice(
        paginationList.length - 5,
        paginationList.length - 1
      );
    } else {
      setStartEllipsis(true);
      setEndEllipsis(true);
      return paginationList.slice(ActiveItem - 3, ActiveItem + 2);
    }
  }

  useEffect(() => {
    setFinalList(renderList(paginationList));
  }, [ActiveItem, paginationList]);

  return (
    <Wrapper>
      <List>
        <PrevBtn isDisabled={ActiveItem === 1} onClick={() => prevItem()}>
          Назад
        </PrevBtn>

        <Item isActive={ActiveItem === 1} onClick={() => changeActiveItem(1)}>
          1
        </Item>
        <Item
          isMore={true}
          onClick={() => changeActiveItem(1)}
          isDisplay={!isStartEllipsis}
        >
          ...
        </Item>
        {finalList?.map((paginationItem) => {
          return (
            <Item
              key={paginationItem}
              isActive={ActiveItem === paginationItem}
              onClick={() => changeActiveItem(paginationItem)}
            >
              {paginationItem}
            </Item>
          );
        })}
        <Item
          isMore={true}
          onClick={() => changeActiveItem(paginationList.length)}
          isDisplay={!isEndEllipsis}
        >
          ...
        </Item>
        <Item
          isActive={ActiveItem === paginationList.length}
          onClick={() => changeActiveItem(paginationList.length)}
        >
          {paginationList.length}
        </Item>

        <NextBtn
          isDisabled={ActiveItem === paginationList.length}
          onClick={() => nextItem()}
        >
          Далее
        </NextBtn>
      </List>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  ${media.mobile(css`
    justify-content: center;
  `)}
`;

const List = styled.div`
  display: flex;
  align-items: flex-end;
  margin: 0 -5px;

  ${media.mobile(css`
    margin: -7px;
    align-items: center;
    flex-wrap: wrap;
  `)}
`;

const Item = styled.div<{
  isActive?: boolean;
  isMore?: boolean;
  isDisplay?: boolean;
}>`
  margin: 0 5px;
  cursor: pointer;
  padding: 8px;
  min-width: 42px;
  text-align: center;
  color: #000;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.3;
  transition: all 0.3s linear;
  border-radius: 9px;

  ${(props) =>
    props.isActive
      ? css`
          background: ${colors.orange};
          color: ${colors.white};
          pointer-events: none;
        `
      : css`
          background: #f2f2f2;
        `}

  ${(props) =>
    props.isMore
      ? css`
          pointer-events: none;
        `
      : ''}
  
  ${(props) =>
    props.isDisplay
      ? css`
          display: none;
        `
      : ''}

  ${media.laptop(css`
    font-size: 16px;
    line-height: 1.6;
  `)}
  
  ${media.mobile(css`
    min-width: 32px;
    font-size: 12px;
    line-height: 20px;
    padding: 8px;
    margin-top: 7px;
    margin-bottom: 7px;
  `)}
  
    ${media.laptopUp(css`
    &:hover {
      background: ${colors.orange};
      color: white;
    }
  `)}
`;

const PrevBtn = styled.div<{ isDisabled: boolean }>`
  margin-right: 5px;
  padding: 8px 22px;
  cursor: pointer;
  border-radius: 9px;
  color: #000;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.3;
  background: #fbec5e;

  ${(props) =>
    props.isDisabled
      ? css`
          opacity: 0;
          visibility: hidden;
          pointer-events: none;
        `
      : ''}
  ${media.mobile(css`
    display: none;
  `)}
`;

const NextBtn = styled.div<{ isDisabled: boolean }>`
  position: relative;
  margin-left: 5px;
  padding: 8px 22px;
  cursor: pointer;
  border-radius: 9px;
  color: #000;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.3;
  background: #fbec5e;

  ${media.mobile(css`
    font-size: 12px;
    line-height: 20px;
    padding: 8px;
    margin: 7px;
  `)}

  ${(props) =>
    props.isDisabled
      ? css`
          opacity: 0;
          visibility: hidden;
          pointer-events: none;
        `
      : ''}
`;

export default Pagination;

