import React, { useMemo, useState } from 'react';
import styled, { css } from 'styled-components';

import { ModalProps } from '@tager/web-components';

import { media } from '@/utils/mixin';
import { useTypedSelector } from '@/store/store';
import { ReactComponent as IconNext } from '@/assets/svg/icon-menu-next.svg';
import { ReactComponent as IconBack } from '@/assets/svg/slider-prev.svg';
import { ReactComponent as IconClose } from '@/assets/svg/close-message.svg';
import { selectShopByCitiesList } from '@/store/reducers/shopsCities';
import { selectPromotionsCategoriesList } from '@/store/reducers/promotionCategories';
import { selectPromotionsSubCategoriesList } from '@/store/reducers/promotionSubcategories';
import { selectPromotionsTypesList } from '@/store/reducers/promotionTypes';
import { selectMetaPage } from '@/store/reducers/products';

type Props = ModalProps<{
  category: number;
  subcategory: number;
  shop: number;
  city: number;
  type: number;
  onTapCity: (value: number) => void;
  onTapShop: (value: number) => void;
  onTapType: (value: number) => void;
  onTapCategory: (value: number) => void;
  onTapSubcategory: (value: number) => void;
}>;

function MobileFilterModal({ innerProps, closeModal }: Props) {
  const promotionTypes = useTypedSelector(selectPromotionsTypesList);
  const cities = useTypedSelector(selectShopByCitiesList);
  const categories = useTypedSelector(selectPromotionsCategoriesList);
  const subcategories = useTypedSelector(selectPromotionsSubCategoriesList);
  const metaPage = useTypedSelector((state) => selectMetaPage(state));
  const [activeCategory, setActiveCategory] = useState<number>(
    innerProps.category
  );

  const [activeSubcategory, setActiveSubcategory] = useState<number>(
    innerProps.subcategory
  );

  const [activeShop, setActiveShop] = useState<number>(innerProps.shop);
  const [activeType, setActiveType] = useState<number>(innerProps.type);

  const [activeCity, setActiveCity] = useState<number>(innerProps.city);
  const [select, setSelect] = useState({ id: -1, name: 'Фильтры' });

  function resetFilter() {
    setActiveType(0);
    innerProps.onTapCity(0);

    setActiveSubcategory(0)
    innerProps.onTapSubcategory(0)

    setActiveCategory(0)
    innerProps.onTapCategory(0)

    setActiveShop(0)
    innerProps.onTapShop(0)

    setActiveCity(0)
    innerProps.onTapCity(0)

    setActiveType(0)
    innerProps.onTapType(0)

    setSelect(INITIAL_VALUE);
  }

  const currentShops = useMemo<any>(() => {
    const data: any[] = [];
    data.push({
      id: 0,
      name: 'Все магазины',
      urlAlias: null,
      pageTitle: null,
      pageDescription: null,
      openGraphImage: null,
    });

    if (activeCity > 0) {
      cities.forEach((item) => {
        if (item.id === activeCity) {
          item.shops.forEach((shop) => {
            data.push(shop);
          });
        }
      });

      return data;
    } else {
      cities.forEach((item) => {
        item.shops.forEach((shop) => {
          data.push(shop);
        });
      });
    }
    return data;
  }, [cities, activeCity]);

  const ALL_FILTERS = -1;
  const filtersProduct = {
    all: -1,
    city: 1,
    shop: 2,
    category: 3,
    subcategory: 4,
    type: 5,
  };
  const FILTERS = [
    {
      id: filtersProduct.city,
      name: 'Город',
      activeItem:
        cities.find((item) => item.id === activeCity)?.name ?? 'Все города',
    },
    {
      id: filtersProduct.shop,
      name: 'Магазин',
      activeItem:
        currentShops.find((item: { id: number; name: string }) => item.id === activeShop)
          ?.name ?? 'Все магазины',
    },
    {
      id: filtersProduct.category,
      name: 'Категории',
      activeItem:
        categories.find((item) => item.id === activeCategory)?.name ?? 'Все категории',
    },
    {
      id: filtersProduct.subcategory,
      name: 'Подкатегории',
      activeItem: subcategories.find((item) => item.id === activeSubcategory)?.name ?? 'Все подкатегории',
    },
    {
      id: filtersProduct.type,
      name: 'Акции',
      activeItem:
        promotionTypes.find((item) => item.id === activeType)?.name ?? 'Все акции',
    },
  ];

  const INITIAL_VALUE = {
    id: ALL_FILTERS,
    name: 'Фильтры',
  };

  const data = useMemo(() => {
    if (select.id === filtersProduct.city) {
      return [
        {
          id: 0,
          name: 'Все города',
          urlAlias: null,
          pageTitle: null,
          pageDescription: null,
          openGraphImage: null,
        },
        ...cities,
      ];
    }

    if (select.id === filtersProduct.shop) {
      return currentShops;
    }

    if (select.id === filtersProduct.category) {
      return [
        {
          id: 0,
          name: 'Все категории',
          urlAlias: null,
          pageTitle: null,
          pageDescription: null,
          openGraphImage: null,
        },
        ...categories,
      ];
    }
    if (select.id === filtersProduct.subcategory) {
      return [
        {
          id: 0,
          name: 'Все подкатегории',
          urlAlias: null,
          pageTitle: null,
          pageDescription: null,
          openGraphImage: null,
        },
        ...subcategories,
      ];
    }
    if (select.id === filtersProduct.type) {
      return [
        {
          id: 0,
          name: 'Все акции',
          urlAlias: null,
          pageTitle: null,
          pageDescription: null,
          openGraphImage: null,
        },
        ...promotionTypes,
      ];
    }
    return FILTERS;
  }, [
    categories,
    cities,
    promotionTypes,
    select.id,
    currentShops,
    subcategories,
  ]);

  const onTapFilter = (data: { id: number; name: string }) => {
    if (select.id === -1) {
      setSelect({ id: data.id, name: data?.name ?? '' });
    }

    if (select.id > -1) {
      switch (select.id) {
        case filtersProduct.category:
          innerProps.onTapCategory(data.id);
          setActiveCategory(data.id);
          // if (data.id === 0) {
            setSelect(INITIAL_VALUE);
            innerProps.onTapSubcategory(0);
            setActiveSubcategory(0);
          // } else {
          //   setSelect({
          //     id: filtersProduct.subcategory,
          //     name: 'Подкатегории',
          //   });
          // }

          break;

        case filtersProduct.subcategory:
          innerProps.onTapSubcategory(data.id);
          setActiveSubcategory(data.id);

          setSelect(INITIAL_VALUE);
          break;

        case filtersProduct.shop:
          innerProps.onTapShop(data.id);
          setActiveShop(data.id);
          setSelect(INITIAL_VALUE);

          break;

        case filtersProduct.city:
          innerProps.onTapCity(data.id);
          setActiveCity(data.id);
          setSelect(INITIAL_VALUE);

          break;

        case filtersProduct.type:
          innerProps.onTapType(data.id);
          setActiveType(data.id);

          setSelect(INITIAL_VALUE);

          break;
      }
    }
  };

  const selected = (id: number) => {
    if (select.id === filtersProduct.city) {
      return activeCity === id;
    }

    if (select.id === filtersProduct.subcategory) {
      return activeSubcategory === id;
    }

    if (select.id === filtersProduct.category) {
      return activeCategory === id;
    }

    if (select.id === filtersProduct.shop) {
      return activeShop === id;
    }

    if (select.id === filtersProduct.type) {
      return activeType === id;
    }
    return false;
  };

  const currentCategory = categories.find((item) => item.id === activeCategory);

  const onBackSubcategory = () =>
    setSelect({
      id: filtersProduct.category,
      name: 'Категории',
    });

  const SHOW_PREVIEW_SUBCATEGORY =
    currentCategory &&
    select.id === filtersProduct.subcategory &&
    innerProps.category > 0;


  const SHOW_DEFAULT = select.id === -1;
  return (
    <Wrapper>
      <Row>
        <Row>
          {!SHOW_DEFAULT && (
            <IconBack
              onClick={() => {
                setActiveCategory(0);
                setSelect(INITIAL_VALUE);
              }}
              style={{ height: 30, width: 25, color: '#020202' }}
            />
          )}
          <Title withBorder={select.id === -1}>
            {select.id === -1 ? `Фильтры` : `Все фильтры`}
          </Title>
        </Row>
        <IconClose
          onClick={closeModal}
          style={{ height: 20, width: 20, color: '#020202' }}
        />
      </Row>

      {SHOW_PREVIEW_SUBCATEGORY && (
        <SubRow onClick={onBackSubcategory}>
          <IconBack
            onClick={onBackSubcategory}
            style={{ height: 14, width: 14, color: '#6E6E6E' }}
          />
          <Subtitle>{currentCategory?.name}</Subtitle>
        </SubRow>
      )}
      <Inner>
        {select.id === filtersProduct.shop
          ? currentShops &&
            currentShops.map((item: any) => (
              <Item
                selected={selected(item.id)}
                withBorder={SHOW_DEFAULT}
                onClick={() => {
                  onTapFilter({ id: item.id, name: item?.name ?? '' });
                }}
              >
                <ListTitle>
                  {`${item?.name}`}
                  {item?.address ? `(${item?.address})` : ''}
                </ListTitle>
                {SHOW_DEFAULT && (
                  <IconNext
                    style={{ height: 20, width: 20, color: '#ADADAD' }}
                  />
                )}
              </Item>
            ))
          : data &&
            data.map((city: any) => {
              if (SHOW_DEFAULT && city.id === filtersProduct.subcategory && !activeCategory) {
                return null
              }
              return (
                <Item
                  selected={selected(city.id)}
                  withBorder={SHOW_DEFAULT}
                  onClick={() => {
                    onTapFilter({ id: city.id, name: city?.name ?? '' });
                  }}
                >
                  <ListTitle>{city.name}</ListTitle>
                  {SHOW_DEFAULT && (
                    <>
                      <SelectedItem>{city.activeItem}</SelectedItem>
                      <IconNext
                        style={{ height: 20, width: 20, color: '#ADADAD' }}
                      />
                    </>
                  )}
                </Item>
              )
          })}
      </Inner>
      {SHOW_DEFAULT && (
        <>
          <Button onClick={closeModal}>Показать {metaPage?.total} товаров</Button>
          <Button reset={true} onClick={resetFilter}>Сбросить</Button>
        </>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100vw;
  height: 100dvh;
  padding: 23px 10px 10px;
  position: relative;
  background: white;
  display: flex;
  flex-direction: column;
  
`;

const Inner = styled.div`
  width: 100%;
  max-height: 92%;
  position: relative;
  margin-top: 15px;
  margin-bottom: auto;
  overflow-y: scroll;
  scrollbar-color: #f9683a #f9683a;
`;

const Row = styled.div`
  align-items: center;
  position: relative;
  display: flex;
  justify-content: space-between;
`;

const SubRow = styled.div`
  align-items: center;
  position: relative;
  display: flex;
  justify-content: flex-start;
  padding-top: 36px;
  margin-left: 6px;
`;

const Subtitle = styled.p`
  font-size: 16px;
  color: #6f6f6f;
  font-weight: 500;
  display: inline;
  margin-left: 8px;
`;

const Title = styled.p<{
  withBorder: boolean;
}>`
  font-size: 20px;
  color: #000000;
  font-weight: 700;
  display: inline;
  margin-left: ${(props) => (props.withBorder ? '0px' : '12px')};
  ${media.mobileSmall(css`
    font-size: 16px;
  `)}
`;

const ListTitle = styled.p`
  font-size: 18px;
  line-height: 1.2;
  color: #000000;
  font-weight: 500;
  display: inline;
  margin-right: auto;
  
`;

const SelectedItem = styled.span`
  display: inline-block;
  margin-right: 10px;
  margin-left: 10px;
  color: #909090;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.28;
`;

const Item = styled.div<{
  withBorder: boolean;
  selected: boolean;
}>`
  padding-left: ${(props) => (props.withBorder ? '0px' : '36px')};
  align-items: center;
  position: relative;
  display: flex;
  background: ${(props) => (props.selected ? '#F1F1F1' : null)};
  border-radius: ${(props) => (props.selected ? '8px' : null)};
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: ${(props) => (props.withBorder ? '1px solid #c6c6c6' : null)};
`;

const Button = styled.div<{ reset?: boolean; }>`
  max-width: 723px;
  width: 100%;
  margin: 15px auto 0;
  padding: 13px;
  border-radius: 10px;
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.1;
  color: ${(props) => (props.reset ? '#000' : '#fff')};
  background: ${(props) => (props.reset ? '#f2f2f2' : '#f9683a')};
`;


export default MobileFilterModal;
