import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';
import Picture from '@/components/Picture';
import {PromotionProductsItem, StringField} from '@/typings/model';
import { ReactComponent as WeightIcon } from '@/assets/svg/weight.svg';

import {convertThumbnailToModalPictureImage, convertThumbnailToPictureImage} from './helpers';
import PromotionPrice from './PromotionPrice';
import PromotionCounter from './PromotionCounter';

interface Props {
  product: PromotionProductsItem;
}

function PromotionCard({ product }: Props) {
  const {
    image,
    name,
    weight,
    date,
    days,
    price,
    discountPrice,
    discount,
    stickerText,
    stickerColor,
    priceUnit,
    pricePerKg,
  } = product;

  return (
    <Component
    >
      <Inner>
        <Head>
          {image ? (
            <Picture mobileSmall={convertThumbnailToPictureImage(image)} tabletSmall={convertThumbnailToModalPictureImage(image)} />
          ) : null}

          {stickerText ? (
            <Sticker stickerColor={stickerColor}>
              {stickerText}
            </Sticker>
          ) : null}

          {days ? (
            <PromotionCounter days={days} />
          ) : null}

          {discount ? (
            <DiscountPercent>{discount}</DiscountPercent>
          ) : null}

          {weight ? (
            <WeightSticker>
              <WeightIcon />
            </WeightSticker>
          ) : null}

          {weight ? (
            <WeightCaption>кг</WeightCaption>
          ) : null}
        </Head>

        <Body>
          <PromotionPrice
            priceOld={price}
            priceNew={discountPrice}
            unit={priceUnit}
            perUnit={pricePerKg}
          />

          <Date>{date}</Date>

          <Name>{name}</Name>
        </Body>
      </Inner>
    </Component>
  );
}

const Component = styled.div`
  position: relative;
  padding: 12px;  
  flex: 1 1 20%;
  max-width: 20%;

  ${media.desktopLarge(css`
    padding: 16px;
  `)}

  ${media.tabletLarge(css`
    flex: 1 1 33.33%;
    max-width: 33.33%;
    padding: 17px;
  `)}
  
  ${media.mobile(css`
    flex: 1 1 50%;
    max-width: 50%;
  `)};

  ${media.mobileMedium(css`
    padding: 5px;
  `)};

  ${media.mobileSmall(css`
    flex: 1 1 100%;
    max-width: 100%;
    padding: 12px 0;
  `)};
`;


const Inner = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  background: #F5F5F5;
`;

const Head = styled.div`
  position: relative;
  display: flex;
  min-height: 150px;
  
  & > div {
    flex-grow: 1;
  }
  
  img {
    max-width: 100%;
    width: 100%;
    max-height: 260px;
    height: auto;
    transition: transform 0.3s ease;
    
    &:hover {
      transform: scale(1.12);
    }

    ${media.mobile(css`
        &:hover {
          transform: none;
        }
  `)};
  }

  picture {
    max-width: 260px;
    width: 100%;
  }
`;

const Sticker = styled.span<{ stickerColor: StringField }>`
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 15px 15px 15px 0;
  display: inline-block;
  padding: 4px 10px;
  color: #FFF;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.33;

  ${media.laptop(css`
    font-size: 11px;
    line-height: 1.8;
    padding: 0 9px 1px;
  `)};

  ${(props) =>
    props.stickerColor
      ? css`
        background: ${props.stickerColor}; 
      `
      : css`
        background: rgb(249, 104, 58);
      `}
`;

const DiscountPercent = styled.span`
  position: absolute;
  left: 0;
  bottom: 0;
  display: inline-block;
  padding: 0 9px;
  border-radius: 0 15px 15px 0;
  background: #F9683A;
  color: #FFF;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.3;

  ${media.laptop(css`
    font-size: 15px;
    line-height: 1.73;
    padding: 0 8px;
  `)};
`;

const WeightSticker = styled.div`
  position: absolute;
  right: 13px;
  bottom: 0;
  display: flex;
`;

const WeightCaption = styled.span`
  position: absolute;
  right: 20px;
  bottom: 0;
  color: #FFF;
  font-size: 10px;
  font-weight: 700;
  width: 13px;
  height: 19px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  padding: 13px 13px 20px;

  ${media.laptop(css`
    padding-top: 10px;
  `)};
`;

const Date = styled.div`
  margin-top: 13px;
  color: #909090;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.3;
  line-height: 1.3;

  ${media.laptop(css`
    margin-top: 10px;
    font-size: 15px;
    line-height: 1.33;
  `)};
`;

const Name = styled.div`
  margin-top: 13px;
  color: #1F1F1F;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.3;
  margin-bottom: auto;

  ${media.laptop(css`
    margin-top: 10px;
    font-size: 15px;
    line-height: 1.33;
  `)};
`;

export default PromotionCard;
