import React from 'react';
import styled, { css } from 'styled-components';
import PromotionCard from '@/modules/Promotions/components/PromotionCard';
import { PromotionProductsItem } from '@/typings/model';
import { media } from '@/utils/mixin';

interface Props {
  activeProducts: Array<PromotionProductsItem>;
}

function PromotionsDesktop({ activeProducts }: Props) {
  return (
    <Component>
      {activeProducts.map((product, index) => {
        return (
          <PromotionCard
            key={index}
            product={product}
          />
        );
      })}
    </Component>
  );
}

const Component = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -12px;
  width: calc(100% + 24px);

  ${media.desktopLarge(css`
    margin: -16px;
    width: calc(100% + 32px);
  `)}

  ${media.tabletLarge(css`
    margin: -17px;
    width: calc(100% + 34px);
  `)}

  ${media.mobileMedium(css`
    margin: -5px;
    width: calc(100% + 10px);
  `)};

  ${media.mobileSmall(css`
    margin: -12px 0;
    width: 100%;
  `)};
`;

export default PromotionsDesktop;
