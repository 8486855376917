import React from 'react';
import styled, { css } from 'styled-components';
import { media } from '@/utils/mixin';

import { ReactComponent as InfoIcon } from '@/assets/svg/promotions-info.svg';

interface Props {
  text: string;
}


function  PromotionDescription({ text }: Props) {
  return (
    <Component>
      <Inner>
          <InfoIcon />
          <PromotionText>
            {text}
          </PromotionText>
      </Inner>
    </Component>
  );
}

const Component = styled.div`
  max-width: 1680px;
  margin: 0 auto 75px;
  padding: 0 40px;

  ${media.tablet(css`
    padding: 0 20px;
    margin-bottom: 25px;
  `)}
`;

const Inner = styled.div`
  max-width: 780px;
  margin-right: auto;
  display: flex;
  
  & > svg {
    min-width: 18px;
    margin-top: 4px;
  }

  ${media.laptop(css`
    max-width: 566px;
  `)}

  ${media.tablet(css`
    max-width: 730px;
    & > svg {
      min-width: 13px;
      margin-top: 2px;
    }
  `)}
`;

const PromotionText = styled.div`
  margin-left: 9px;
  color: #1F1F1F;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.3;

  ${media.tablet(css`
    font-size: 15px;
    margin-left: 4px;
  `)}
`;

export default PromotionDescription;
