import React from 'react';
import styled, { css } from 'styled-components';
import { media } from '@/utils/mixin';

interface Props {
  days: number;
}

function PromotionCounter({ days }: Props) {
  let caption = 'дней'

  if (days === 1) {
    caption = 'день'
  }

  if (days === 2 || days === 3 || days === 4) {
    caption = 'дня'
  }

  return (
    <Component>
      <Inner>
        {days}
        <Caption>{caption}</Caption>
      </Inner>
    </Component>
  );
}

const Component = styled.div`
  position: absolute;
  top: 0;
  right: 25px;
  color: #FFF;
  font-size: 24px;
  line-height: 0.75;
  font-weight: 500;
  min-width: 42px;
  background: #E5001C;
  display: flex;
  flex-direction: column;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 50% calc(100% - 11px), 0 100%);

  ${media.laptop(css`
    right: 20px;
    font-size: 20px;
    line-height: 0.9;
    min-width: 31px;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 50% calc(100% - 9px), 0 100%);
  `)};

  ${media.mobileMedium(css`
    right: 13px;
    font-size: 16px;
    line-height: 1.12;
    min-width: 23px;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 50% calc(100% - 7px), 0 100%);
  `)};
`;

const Inner = styled.div`
  padding: 11px 8px 21px 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Caption = styled.div`
  font-size: 15px;
  line-height: 1.2;
  display: inline-block;
  margin-top: 5px;

  ${media.laptop(css`
    font-size: 11px;
    line-height: 1.36;
  `)};
`;

export default PromotionCounter;
