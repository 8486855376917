import React from 'react';
import styled, { css } from 'styled-components';
import { media } from '@/utils/mixin';

interface Props {
  unit: string | null;
  priceOld: number | null;
  priceNew: number;
  perUnit: number;
}

function PromotionPrice({
  unit,
  perUnit,
  priceNew,
  priceOld,
}: Props) {
  return (
    <Component>
      <PriceWrapper>
        {priceOld && priceOld !== 0 ? (
          <PriceOld>
              {String(priceOld.toFixed(2))} <PriceOldCurrency>руб.</PriceOldCurrency>
          </PriceOld>
        ) : null}
        <PriceNew>
          {String(priceNew.toFixed(2))} <PriceNewCurrency>руб.</PriceNewCurrency>
        </PriceNew>
      </PriceWrapper>

      {perUnit && unit ? (
        <PricePerUnit>
          {`${perUnit} руб. за 1 ${unit}`}
        </PricePerUnit>
      ) : null}
    </Component>
  );
}

const Component = styled.div`
  display: flex;
  justify-content: space-between;
  white-space: nowrap;
  flex-wrap: wrap-reverse;
`;

const PriceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 4px;
`;

const PriceOld = styled.span`
  align-self: flex-start;
  display: inline-block;
  margin-bottom: 3px;
  color: #909090;
  font-size: 22px;
  font-weight: 400;
  line-height: 1.19;
  position: relative;

  ${media.laptop(css`
    font-size: 15px;
    line-height: 1.2;
    margin-bottom: 0;
  `)};

  &:before {
    content: '';
    width: 100%;
    height: 1px;
    background: #909090;
    position: absolute;
    bottom: 45%;

    ${media.laptop(css`
      bottom: 47%;
  `)};
  }
`;

const PriceOldCurrency = styled.span`
  font-size: 18px;
  line-height: 1.44;

  ${media.laptop(css`
    font-size: 15px;
    line-height: 1.73;
  `)};
`;

const PriceNew = styled.span`
  color: #F9683A;
  font-size: 20px;
  font-weight: 700;
  line-height: 1;

  ${media.desktopLarge(css`
    font-size: 26px;
  `)}
`;

const PricePerUnit = styled.span`
  color: #909090;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.62;

  ${media.laptop(css`
    font-size: 12px;
    line-height: 2;
  `)};
`;

const PriceNewCurrency = styled.span`
  font-size: 16px;
  line-height: 1.25;
  
  ${media.desktopLarge(css`
    font-size: 23px;
    line-height: 1.13;
  `)}
`;

export default PromotionPrice;
