import React, { RefObject, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { useRouter } from 'next/router';
import { useDispatch, useStore } from 'react-redux';

import { useModal } from '@tager/web-components';

import PromotionList from '@/modules/Promotions/components/PromotionList';
import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import { PromotionProductsItem } from '@/typings/model';
import { StoreDispatch, useTypedSelector } from '@/store/store';
import { selectMetaPage, selectProductList } from '@/store/reducers/products';
import { getParamAsString } from '@/utils/common';
import {
  getAdditionFiltersByAlias,
  saveAdditionFiltersByAliasThunk,
} from '@/store/reducers/filter';
import { getPromotionsSubCategoriesListThunk } from '@/store/reducers/promotionSubcategories';
import useWindowSize from '@/hooks/useWindowSize';
import { ReactComponent as IconFilter } from '@/assets/svg/icon-filter.svg';
import MobileFilterModal from '@/components/modals/MobileFilterModal';

import CitiesSelect from './Filters/CitiesSelect';
import ShopsSelect from './Filters/ShopsSelect';
import CategoriesSelect from './Filters/CategoriesSelect';
import SubcategoriesSelect from './Filters/SubcategoriesSelect';
import TypesSelect from './Filters/TypesSelect';

export interface InitialValueProps {
  city: number;
  shop: number;
  category: number;
  subcategory: number;
  type: number;
}

interface Props {
  title: string;
  componentRef: RefObject<HTMLDivElement>;
}

function PromotionContent({ title, componentRef }: Props) {
  const windowSize = useWindowSize();

  const router = useRouter();
  const store = useStore();
  const dispatch = useDispatch<StoreDispatch>();

  const openModal = useModal();

  const onActiveCategory = (id: number) => {
    store.dispatch(
      // @ts-ignore
      getPromotionsSubCategoriesListThunk({
        activeCategory: id,
        shouldInvalidate: false,
      })
    );
    setActiveCategory(id);
  };

  const onTapCity = (id: number) => {
    setActiveCity(id);
  };

  const onTapShop = (id: number) => {
    setActiveShop(id);
  };

  const onTapType = (id: number) => {
    setActiveType(id);
  };
  const onActiveSubcategory = (id: number) => {
    setActiveSubCategory(id);
  };

  const openFilterModal = () => {
    openModal(MobileFilterModal, {
      category: activeCategory,
      subcategory: activeSubCategory,
      shop: activeShop,
      city: activeCity,
      type: activeType,
      onTapCity: onTapCity,
      onTapType: onTapType,
      onTapShop: onTapShop,
      onTapCategory: onActiveCategory,
      onTapSubcategory: onActiveSubcategory,
    });
  };

  const filters = useTypedSelector((state) =>
    getAdditionFiltersByAlias(
      state,
      getParamAsString('/hypermarkets/promotions')
    )
  );

  const [rendered, setRendered] = useState<boolean>(false);

  const [activeCity, setActiveCity] = useState<number>(
    filters?.city ? Number(filters.city) : 0
  );
  const [activeShop, setActiveShop] = useState<number>(
    filters?.shop ? Number(filters.shop) : 0
  );

  const [activeCategory, setActiveCategory] = useState<number>(
    filters?.category ? Number(filters.category) : 0
  );

  const [activeSubCategory, setActiveSubCategory] = useState<number>(
    filters?.subcategory ? Number(filters.subcategory) : 0
  );

  const [activeType, setActiveType] = useState<number>(
    filters?.type ? Number(filters.type) : 0
  );

  const [activeProducts, setActiveProducts] = useState<
    Array<PromotionProductsItem>
  >([]);

  const metaPage = useTypedSelector((state) => selectMetaPage(state));

  const [ActiveItem, setActiveItem] = useState<number>(
    metaPage?.page?.number ?? 1
  );

  const productList = useTypedSelector((state) => {
    return selectProductList(state);
  });

  useEffect(() => {
    setActiveProducts(productList.data);
  }, [productList]);

  useEffect(() => {
    if (!rendered) {
      setRendered(true);
      return;
    }

    dispatch(
      saveAdditionFiltersByAliasThunk(
        getParamAsString('/hypermarkets/promotions'),
        {
          city: activeCity,
          shop: activeShop,
          category: activeCategory,
          subcategory: activeSubCategory,
          type: activeType,
        }
      )
    );

    router.push({
      pathname: getParamAsString('/hypermarkets/promotions'),
      query: {
        city: activeCity ? activeCity : [],
        shop: activeShop ? activeShop : [],
        category: activeCategory ? activeCategory : [],
        subcategory: activeSubCategory ? activeSubCategory : [],
        type: activeType ? activeType : [],
      },
    });
  }, [activeCity, activeCategory, activeSubCategory, activeShop, activeType]);

  useEffect(() => {
    setActiveItem(metaPage?.page?.number ?? 1);
  }, [metaPage?.page.number]);

  function selectPage(page: number) {
    if (componentRef?.current) {
      const y =
        componentRef?.current.getBoundingClientRect().top +
        window.pageYOffset -
        100;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }

    router.push({
      pathname: getParamAsString('/hypermarkets/promotions'),
      query: {
        page: page,
        city: activeCity ? activeCity : [],
        shop: activeShop ? activeShop : [],
        category: activeCategory ? activeCategory : [],
        subcategory: activeSubCategory ? activeSubCategory : [],
        type: activeType ? activeType : [],
      },
    });
  }

  useEffect(() => {
    dispatch(getPromotionsSubCategoriesListThunk({
      activeCategory: activeCategory,
      shouldInvalidate: false,
    }))
  }, []);

  return (
    <Component>
      <Inner>
        <Top>
          <Title>{title}</Title>
          <Info>{metaPage?.total} товаров</Info>
        </Top>
        {windowSize.width <= 768 ? (
          <Filter>
            <ButtonFilter onClick={() => openFilterModal()}>
              <IconFilter width={17} height={22} />
              <BtnFilterTitle>Фильтр</BtnFilterTitle>
            </ButtonFilter>
          </Filter>
        ) : (
          <Filter>
            <FilterCol>
              <CitiesSelect
                setActiveCity={(value: number) => setActiveCity(value)}
                activeCity={activeCity}
              />
            </FilterCol>
            <FilterCol>
              <ShopsSelect
                setActiveShop={(value: number) => setActiveShop(value)}
                activeShop={activeShop}
                activeCity={activeCity}
              />
            </FilterCol>
            <FilterCol>
              <CategoriesSelect
                setActiveCategory={async (value: number) => {
                  setActiveSubCategory(0);

                  store.dispatch(
                    // @ts-ignore
                    getPromotionsSubCategoriesListThunk ({
                      activeCategory: value,
                      shouldInvalidate: false,
                    })
                  );
                  setActiveCategory(value);
                }}
                activeCategory={activeCategory}
              />
            </FilterCol>

            {activeCategory > 0 && (
              <FilterCol>
                <SubcategoriesSelect
                  setActiveSubCategory={(value: number) =>
                    setActiveSubCategory(value)
                  }
                  selected={activeSubCategory}
                />
              </FilterCol>
            )}
            <FilterCol>
              <TypesSelect
                setActiveType={(value: number) => setActiveType(value)}
                activeType={activeType}
              />
            </FilterCol>
          </Filter>
        )}
        <PromotionList
          activeProducts={activeProducts}
          ActiveItem={ActiveItem}
          setActiveItem={(value: number) => setActiveItem(value)}
          componentRef={componentRef}
          metaPage={metaPage}
          onClick={selectPage}
        />
      </Inner>
    </Component>
  );
}

const ButtonFilter = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 107px;
  height: 37px;
  background: #f1f1f1;
  border-radius: 10px;
  ${media.mobileSmall(css`
    width: 101.21px;
    height: 35px;
  `)}
`;

const BtnFilterTitle = styled.p`
  font-size: 16px;
  color: #000000;
  font-weight: 500;
  padding-left: 8px;
  ${media.mobileSmall(css`
    font-size: 14px;
  `)}
`;

const Component = styled.div`
  max-width: 1680px;
  margin: 47px auto 100px;
  padding: 0 40px;

  ${media.tablet(css`
    margin: 29px 0 70px;
    padding: 0 20px;
  `)}

  ${media.mobile(css`
    margin: 20px 0 58px;
  `)}
`;
const Inner = styled.div``;

const Top = styled.div`
  display: flex;
  align-items: flex-end;

  ${media.tablet(css`
    align-items: center;
  `)}

  ${media.mobile(css`
    display: block;
  `)}
`;

const Filter = styled.div`
  display: flex;
  margin: 29px -19px 0;

  ${media.laptop(css`
    margin: 24px -15px 0;
  `)}

  ${media.tablet(css`
    margin: 15px -15px -5px;
    flex-wrap: wrap;
  `)}

  ${media.mobile(css`
    margin: 27px 0px;
  `)}
`;

const FilterCol = styled.div`
  padding: 0 19px;

  ${media.laptop(css`
    padding: 0 15px;
  `)}

  ${media.tablet(css`
    padding: 5px 15px;
  `)}

  ${media.mobile(css`
    padding: 5px 8px;
  `)}
`;

const Title = styled.h2`
  margin: 0 20px 0 0;
  font-weight: 500;
  font-size: 50px;
  line-height: 58px;
  color: ${colors.black};

  ${media.laptop(css`
    font-size: 48px;
    line-height: 56px;
    margin: 0 16px 0 0;
  `)}

  ${media.tablet(css`
    font-size: 32px;
    line-height: 38px;
  `)}

  ${media.mobile(css`
    font-size: 28px;
    line-height: 34px;
    margin: 0 0 20px;
  `)}
`;

const Info = styled.span`
  display: block;
  font-weight: 500;
  font-size: 32px;
  line-height: 42px;
  color: ${colors.grayLight};

  ${media.laptop(css`
    font-size: 24px;
    line-height: 32px;
  `)}

  ${media.tablet(css`
    font-size: 20px;
    line-height: 24px;
  `)}

  ${media.mobile(css`
    font-size: 16px;
    line-height: 20px;
  `)}
`;

export default PromotionContent;
