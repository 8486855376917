import React, { RefObject } from 'react';
import styled, { css } from 'styled-components';

import {
  FETCH_STATUSES,
  generateNumberArray,
  Nullable,
  useMedia,
} from '@tager/web-core';
import { getSettingValueByKey } from '@tager/web-modules';

import { media } from '@/utils/mixin';
import { MetaPage, PromotionProductsItem } from '@/typings/model';
import Pagination from '@/components/Pagination';
import { selectProductList } from '@/store/reducers/products';
import { useTypedSelector } from '@/store/store';
import { selectSettingItemList } from '@/store/reducers/tager/settings';
import { breakpoints, colors } from '@/constants/theme';

import PromotionCard from './PromotionCard';
import PromotionPlaceholderCard from './PromotionPlaceholderCard';
import PromotionsDesktop from './PromotionsCards/PromotionsDesktop';

interface Props {
  activeProducts: Array<PromotionProductsItem>;
  ActiveItem: number;
  setActiveItem: (value: number) => void;
  componentRef: RefObject<HTMLDivElement>;
  metaPage: Nullable<MetaPage>;
  onClick: (page: number) => void;
}

function PromotionList({
  activeProducts,
  setActiveItem,
  ActiveItem,
  metaPage,
  onClick,
}: Props) {
  const isMobile = useMedia(`(max-width: ${breakpoints.tabletSmall - 1}px)`);

  const settingsList = useTypedSelector(selectSettingItemList);
  const numberItemPerPage = Number(
    getSettingValueByKey(settingsList, 'ITEMS_QUANTITY_VALUE')
  );

  function getPaginationList() {
    const pagination: Array<number> = [];
    if (!metaPage) return [];

    for (let index = 1; index <= metaPage.page.count; index++) {
      pagination.push(index);
    }
    return pagination;
  }

  const productList = useTypedSelector((state) => {
    return selectProductList(state);
  });

  return (
    <Component>
      <Inner>
        {productList.status === FETCH_STATUSES.LOADING ? (
          <PlaceholderContainer>
            {generateNumberArray(numberItemPerPage).map((num) => (
              <PromotionPlaceholderCard key={num} />
            ))}
          </PlaceholderContainer>
        ) : productList.data.length ? (
          <PromotionsDesktop activeProducts={activeProducts} />
        ) : (
          <EmptyProductList>
            К сожалению, продуктов в этой категории пока нет.
          </EmptyProductList>
        )}
      </Inner>
      <PaginationWrapper isHidden={getPaginationList().length < 2}>
        <Pagination
          paginationList={getPaginationList()}
          ActiveItem={ActiveItem}
          setActiveItem={(value: number) => setActiveItem(value)}
          onClick={onClick}
        />
      </PaginationWrapper>
    </Component>
  );
}

const Component = styled.div`
  margin-top: 69px;

  ${media.laptop(css`
    margin-top: 36px;
  `)}

  ${media.tablet(css`
    margin-top: 44px;
  `)}

  ${media.mobile(css`
    margin-top: 28px;
  `)}
`;

const Inner = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 85px;

  ${media.laptop(css`
    margin-bottom: 72px;
  `)};

  ${media.mobile(css`
    margin-bottom: 58px;
  `)}
`;

const PlaceholderContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -12px;
  width: calc(100% + 24px);

  ${media.desktopLarge(css`
    margin: -16px;
    width: calc(100% + 32px);
  `)}

  ${media.tabletLarge(css`
    margin: -17px;
    width: calc(100% + 34px);
  `)}

  ${media.mobileMedium(css`
    margin: -5px;
    width: calc(100% + 10px);
  `)};

  ${media.mobileSmall(css`
    margin: -12px 0;
    width: 100%;
  `)};
`;

const PaginationWrapper = styled.div<{ isHidden: boolean }>`
  margin-bottom: 85px;

  ${media.mobile(css`
    margin-bottom: 58px;
  `)}

  ${(props) =>
    props.isHidden
      ? css`
          display: none;
        `
      : ''}
`;

const EmptyProductList = styled.div`
  padding-top: 3vh;
  margin: 0 auto 0 auto;
  font-weight: 500;
  font-size: 30px;
  line-height: 36px;
  color: ${colors.black};

  ${media.laptop(css`
    font-size: 20px;
    line-height: 26px;
  `)}

  ${media.tablet(css`
    line-height: 24px;
  `)}

  ${media.mobile(css`
    font-size: 16px;
    line-height: 24px;
  `)}
`;

export default PromotionList;
